import React from 'react';
import { HeroCoverValues } from 'components/hero/heroCover';
import { Highlight } from 'components/highlights';
import { LinkData } from '../../../appTypes';
import { PrestationProType } from '../prestationPagePros';
import { FrontVideoMessages } from './videoPageTranslations';
import { PrestationFigure } from '../prestationPageFigures';

//don't forget to set this to correct prestation Messages.
const SpecificPageMessages = FrontVideoMessages;

export const svgColor = {
  stopColor1: '#5472FF',
  stopColor2: '#FF3380',
};
export const prestationHighlights: Highlight[] = [
  {
    title: SpecificPageMessages.highlightsPanels_1_title,
    p1: SpecificPageMessages.highlightsPanels_1_p1,
    svg: true,
    svgColor,
  },
  {
    title: SpecificPageMessages.highlightsPanels_2_title,
    p1: SpecificPageMessages.highlightsPanels_2_p1,
    svg: true,
    svgColor,
  },
  {
    title: SpecificPageMessages.highlightsPanels_3_title,
    p1: SpecificPageMessages.highlightsPanels_3_p1,
    svg: true,
    svgColor,
  },
];

export const heroCover: HeroCoverValues = {
  // backgroundImage: VideoHeader,
  h2: SpecificPageMessages.heroTitle,
  p: SpecificPageMessages.heroP,
};

export const PrestationFigures: PrestationFigure[] = [
  {
    label: SpecificPageMessages.figure1Label,
    text: SpecificPageMessages.figure1Text,
    //imgUrl: VideoService1,
  },
  {
    label: SpecificPageMessages.figure2Label,
    text: SpecificPageMessages.figure2Text,
    //imgUrl: VideoService2,
  },
];

export const PrestationInNav: LinkData[] = [
  { label: SpecificPageMessages.nav1, href: '#intro' },
  { label: SpecificPageMessages.nav2, href: '#pros' },
  { label: SpecificPageMessages.nav3, href: '#gallery' },
  { label: SpecificPageMessages.nav4, href: '#usecase' },
];

export const PrestationProsTabSet: PrestationProType[] = [
  {
    label: SpecificPageMessages.advantages1Title,
    href: '#prestationTab1',
    p1: SpecificPageMessages.advantages1P1,
    p2: SpecificPageMessages.advantages1P2,
    // img: VideoAvantages(1),
  },
  {
    label: SpecificPageMessages.advantages2Title,
    href: '#prestationTab2',
    p1: SpecificPageMessages.advantages2P1,
    p2: SpecificPageMessages.advantages2P2,
    // img: VideoAvantages(2),
  },
  {
    label: SpecificPageMessages.advantages3Title,
    href: '#prestationTab3',
    p1: SpecificPageMessages.advantages3P1,
    p2: SpecificPageMessages.advantages3P2,
    // img: VideoAvantages(3),
  },
  {
    label: SpecificPageMessages.advantages4Title,
    href: '#prestationTab4',
    p1: SpecificPageMessages.advantages4P1,
    p2: SpecificPageMessages.advantages4P2,
    // img:
    //   'https://shoootin-paris.s3.eu-west-3.amazonaws.com/front/prestation/video/avantages-4.gif',
  },
  {
    label: SpecificPageMessages.advantages5Title,
    href: '#prestationTab5',
    p1: SpecificPageMessages.advantages5P1,
    p2: SpecificPageMessages.advantages5P2,
    // img:
    //   'https://shoootin-paris.s3.eu-west-3.amazonaws.com/front/prestation/video/avantages-5.gif',
  },
  {
    label: SpecificPageMessages.advantages6Title,
    href: '#prestationTab6',
    p1: SpecificPageMessages.advantages6P1,
    p2: SpecificPageMessages.advantages6P2,
    // img: VideoAvantages(6),
  },
];
