import React, { useState } from 'react';
import { MainLayout } from 'layout/mainLayout';

import { H2, H3 } from 'primitives/appTitle';
import AppSection from 'primitives/appSection';
import AppLinkBar from 'primitives/appLinkBar';

import { AppInternalLink, AppInternalPaths } from 'primitives/appLink';
import HeroCover from 'components/hero/heroCover';
import Highlights from 'components/highlights';
import GalleryGrid from 'components/gallery/galleryGrid';
import BannerGradient from 'components/banner/bannerGradient';
import { AppButton } from 'primitives/appButton';

import { ShText, useShIntlLocale } from '@shoootin/translations';

import { FrontCommonMessages } from '../../../appCommonTranslations';

import { Sticky, StickyContainer } from 'react-sticky';

// @ts-ignore
import '../frontPrestation.less';
import PrestationOthers from '../prestationOthers';
import { getStickyStyle } from '../prestationCommons';
import { FrontVideoMessages } from './videoPageTranslations';
import {
  heroCover,
  PrestationFigures,
  prestationHighlights,
  PrestationInNav,
  PrestationProsTabSet,
} from './videoPageValues';

import PrestationTabsetPros from '../prestationPagePros';
import PrestationPageFigures from '../prestationPageFigures';
import CoverVideo from '../../../components/coverVideo';
import VideoModal from '../../../components/video/videoModal';
import { graphql } from 'gatsby';
import { ServiceVideoPageQuery } from '../../../appGraphQLTypes';
import { getFluidImages } from '../../../appGatsbyImageUtils';
import usePrestationMainImages from '../../../queries/usePrestationMainImages';
import { useServiceProfessionPrices } from '../servicePrices';
import CoverProfessionPrices from '../../../components/coverProfessionPrices';
import Usecase from '../../../components/usecase';

// don't forget to set this to correct prestation Messages.
const SpecificPageMessages = FrontVideoMessages;

const VideoPage = ({ data }: { data: ServiceVideoPageQuery }) => {
  const [videoId, setVideoId] = useState<string>();

  const heroImage = usePrestationMainImages().video;
  // Workaround to include gifs (can't be handled by gatsby-image)
  const [b1, b2, b3, b6] = getFluidImages(data, 'benefitsImages');
  const benefitsImages = [
    b1,
    b2,
    b3,
    require('../../../../imagesProd/prestation_video_avantages-4.gif'),
    require('../../../../imagesProd/prestation_video_avantages-5.gif'),
    b6,
  ];

  const professionPrices = useServiceProfessionPrices('video', [
    'residentialRealEstate',
    'commercialRealEstate',
    'architect',
    'hotel',
    'restaurant',
  ]);

  const locale = useShIntlLocale();
  const useCase = locale === ('fr_FR' || 'fr_BE') ? 'kretz' : 'tollbrothers';

  return (
    <MainLayout className="page-prestation" pageName="prestation_video">
      <HeroCover image={heroImage} values={heroCover} />

      <StickyContainer>
        <div className="section prestation-intro" id="intro">
          <div className="container">
            <Sticky>
              {({ style, isSticky }) => {
                const stickyStyle = getStickyStyle(isSticky);
                return (
                  <nav
                    id="linknav"
                    className={isSticky ? 'fixed' : ''}
                    style={{
                      ...style,
                      ...stickyStyle,
                    }}
                  >
                    <AppLinkBar
                      links={PrestationInNav}
                      scroll={true}
                      modifier="swiper-container-horizontal swiper-container-free-mode"
                    />
                  </nav>
                );
              }}
            </Sticky>
            {/* <ShRow>
              <ShCol sm={{ span: 40, offset: 10 }} xs={60}>
                <CoverPhotoGatsbyImage
                  fluid={data.coverIntroImage!.childImageSharp!.fluid!}
                />
              </ShCol>
            </ShRow> */}
          </div>
          <Highlights highlights={prestationHighlights} />

          {/* <Cover */}
          {/* path={require('images/background/banner-matterport.jpg')} */}
          {/* target="#" */}
          {/* mode="matterport" */}
          {/* /> */}
          <CoverVideo
            path={data.coverHeroVideoImage!.childImageSharp!.fluid!}
            modifier="banner"
            onClick={() =>
              setVideoId('https://www.youtube.com/watch?v=KcBuGOSAIE0')
            }
          />
          <VideoModal videoId={videoId} onClose={() => setVideoId(undefined)} />
          {/* <BannerVideo videoId="KcBuGOSAIE0" /> */}

          <PrestationPageFigures
            figures={PrestationFigures}
            images={getFluidImages(data, 'figureImages')}
            coverCaption={SpecificPageMessages.coverCaption}
          />
        </div>

        <AppSection
          id="pros"
          header={
            <H2>
              <ShText message={SpecificPageMessages.advantagesTitle} />
            </H2>
          }
        >
          <PrestationTabsetPros
            nav={PrestationProsTabSet}
            quarterMode="video"
            images={benefitsImages}
          />
        </AppSection>

        <AppSection
          className="grid-gallery"
          id="gallery"
          header={
            <H2>
              <ShText message={FrontCommonMessages.gallery} />
            </H2>
          }
        >
          <GalleryGrid />
        </AppSection>

        <AppSection
          className="section-usecase"
          id="usecase"
          header={
            <H2>
              <ShText message={FrontCommonMessages.usecase} />
            </H2>
          }
        >
          <Usecase name={useCase} />
        </AppSection>

        <AppSection
          className="prestation-price"
          id="price"
          header={
            <H2>
              <ShText message={SpecificPageMessages.priceTitle1} />
              <br />
              <ShText message={SpecificPageMessages.priceTitle2} />
            </H2>
          }
        >
          <div key="cover-prices">
            <CoverProfessionPrices professionPrices={professionPrices} />
          </div>
        </AppSection>
      </StickyContainer>
      <BannerGradient className="banner--gradient-video">
        <H3>
          <ShText message={SpecificPageMessages.bannerTitle} />
        </H3>
        <p>
          <ShText message={SpecificPageMessages.bannerP} />
        </p>
        <AppInternalLink to={AppInternalPaths.order}>
          <AppButton modifier={['light', 'large']}>
            <ShText message={SpecificPageMessages.bannerCta} />
          </AppButton>
        </AppInternalLink>
      </BannerGradient>

      <PrestationOthers prestationName="video" />
    </MainLayout>
  );
};

export default VideoPage;

export const query = graphql`
  query ServiceVideoPage {
    #coverIntroImage: file(relativePath: { eq: "prestation_video_intro.jpg" }) {
    #  ...PrestationCoverIntroImageFragment
    #}
    coverHeroVideoImage: file(
      relativePath: { eq: "background/banner-matterport.jpg" }
    ) {
      ...AppFluidHeroImageFragment
    }
    figureImages: allFile(
      sort: { fields: name, order: ASC }
      filter: { relativePath: { glob: "prestation_video_service-*.jpg" } }
    ) {
      edges {
        node {
          ...PrestationFigureImageFragment
        }
      }
    }
    benefitsImages: allFile(
      sort: { fields: name, order: ASC }
      filter: { relativePath: { glob: "prestation_video_avantages-*.jpg" } }
    ) {
      edges {
        node {
          ...PrestationBenefitsImageFragment
        }
      }
    }
  }
`;
